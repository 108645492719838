import './ProductsList.css';
import './InventoryReports.css'
import './ProductReports.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartPlus, faChevronRight, faEdit, faListAlt, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import img1 from './images/Asset 5.png';
import img2 from './images/IMG-20240723-WA0008.jpg';
import img3 from './images/bWseWtl0lxGx1c2Qn8u5kySbfXzViOKx970C0gci.jpg';
import img4 from './images/Asset 1.png';
import { useTranslation } from 'react-i18next';
import Header from './Header/Header';
import { NavLink, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { searsh_product, inventory_report, sales_report, vendor_sales_report } from "./Redux/API";
import Loader from './Loader';
import { useEffect } from 'react';

function ProductReports() {
  
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();


  const searsh_products = useSelector(state => state.Data.searsh_product);
  const loadingSearsh_products = useSelector(state => state.Data.loadingSearsh_products);
  const errorSearsh_product = useSelector(state => state.Data.errorSearsh_product);

  
  // **حالة البحث**
  const [searshBroduct, setSearshBroduct] = useState('');
  const handleInputChangeReturns = (e) => {
    setSearshBroduct(e.target.value); 
  };

  useEffect(() => {
      dispatch(searsh_product(searshBroduct)); // استدعاء البحث
  }, [searshBroduct, dispatch]);


  useEffect(() => {
    dispatch(vendor_sales_report())
  }, [])




  const vendor_sales_reports = useSelector(state => state.Data.vendor_sales_report);
  const loadingVendor_sales_reports = useSelector(state => state.Data.loadingLow_stockS);
  console.log(vendor_sales_reports)
  console.log('77777777777777777777777777777777777777788888888888888888888')

  const listOrders = ['Image', 'Name', 'Category', 'Price'];



  return (
    <div className="ProductsList">
      <Header />
      <div className='container-fluid'>
        {loadingVendor_sales_reports && <Loader />}
        <div className='row align-items-center'>
          <div className='name_header d-flex'>
            <i className='font-size3'><FontAwesomeIcon icon={faListAlt} /></i>
            <p>{t('products_list.products_list')}</p>
          </div>
        </div>

        <div className='container'>
          <div className='row mt-4'>
            <div className='sarsh col-5'>
            <input type="text" value={searshBroduct} onChange={handleInputChangeReturns} placeholder={t('products_list.search')} />
            </div>
          </div>
        </div>

        <div className='container'>
          <div className='Orders'>

            <div className='title_color mt-4 mb-4'>
                <h5 className='mb-3'>المنتجات الأكثر مبيعاً</h5>
                <div className='Orders_border row justify-content-between'>
                    {listOrders.map((e, index) => (
                        <p className='col-3 text-center mb-3' key={index}>{e}</p>
                    ))}
                </div>
            </div>


            {vendor_sales_reports?.data?.data?.top_selling_products.map((item) => (
              <div key={item.id}>
                <div className='Orders_border row align-items-center justify-content-between'>
                  <p className='col-3 text-center'>
                    <img src={item?.product?.main_image} alt={item.id}/>
                  </p>
                  <p className='col-3 text-center'>{item?.product?.name}</p>
                  <p className='col-3 text-center'>{item?.product?.category}</p>
                  <p className='col-3 text-center'>{item?.product?.new_price}</p>
                </div>
              </div>
            ))}

            <div className='title_color mt-4 mb-4'>
                <h5 className='mb-3'>المنتجات الأقل مبيعا</h5>
                <div className='Orders_border row justify-content-between'>
                    {listOrders.map((e, index) => (
                        <p className='col-3 text-center mb-3' key={index}>{e}</p>
                    ))}
                </div>
            </div>



            {vendor_sales_reports?.data?.data?.least_selling_products.map((item) => (
              <div key={item.id}>
                <div className='Orders_border row align-items-center justify-content-between'>
                  <p className='col-3 text-center'>
                    <img src={item?.product?.main_image} alt={item.id}/>
                  </p>
                  <p className='col-3 text-center'>{item?.product?.name}</p>
                  <p className='col-3 text-center'>{item?.product?.category}</p>
                  <p className='col-3 text-center'>{item?.product?.new_price}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductReports;