import './App.css';
import {BrowserRouter, Routes, Route } from "react-router-dom";
import { useState } from 'react';
import Sidebar from './Main/Sidebar';
import Statistics from './Main/Statistics';
import OrdersManagement from './Main/OrdersManagement';
import 'bootstrap/dist/css/bootstrap.min.css';
import Customers from './Main/Customers';
import DataCustomers from './Main/DataCustomers';
import Categoires from './Main/Categoires';
import AddCategoire from './Main/AddCategoire';
import CategoiresDetial from './Main/CategoiresDetial';
import Transactions from './Main/Transactions';
import Brand from './Main/Brand';
import AddCompany from './Main/AddCompany';
import Adveritsement from './Main/Adveritsement';
import AddAdveritsement from './Main/AddAdveritsement';
import ProductsList from './Main/ProductsList';
import AddProduct from './Main/AddProduct';
import Favorites from './Main/Favorites';
import Vendor from './Main/Vendor';
import DataVendor from './Main/DataVendor';
import AddCoupon from './Main/AddCoupon';
import Cart from './Main/Cart';
import Register from './Main/Register/Register';
import Invoice from './Main/Invoice';
import Header from './Main/Header/Header'
import CustomerReports from './Main/CustomerReports';
import InventoryReports from './Main/InventoryReports';
import AddAllCoupon from './Main/AddAllCoupon';
import CreateDiscount from './Main/CreateDiscount';
import AddDiscount from './Main/AddDiscount';



import VendoresStatistics from './Main/VendoresStatistics';
import VendorProduct from './Main/VendorProduct';
import ProductReports from './Main/ProductReports';
import CategorysVendors from './Main/CategorysVendors';
import Profile from './Main/Profile';
import VendorOrderManagement from './Main/VendorOrderManagement';



import { useEffect } from 'react';



function App() {

const getLocalStorage = window.localStorage.getItem('UserInfo');

// تحليل البيانات إذا كانت موجودة
const valueLocalStorage = getLocalStorage ? JSON.parse(getLocalStorage) : null;

const token = valueLocalStorage && valueLocalStorage.data 
  ? (valueLocalStorage?.data?.token || 
     (valueLocalStorage?.data?.data && valueLocalStorage.data.data.user 
      ? valueLocalStorage?.data?.data?.user?.token 
      : null)) 
  : null;
  return (
<div className='App'>
{/* {(valueLocalStorage?.data?.user?.status || 
    (Array.isArray(valueLocalStorage?.data) && valueLocalStorage.data[0]?.status === 'Pending Approval')) && (
      <div className='back'>
        <h3>Waiting for approval from the administrator.</h3>
      </div>
  )} */}

      <BrowserRouter>
        <>
          {!token && <Register />}
          {/* <Register /> */}

          <div className='link'>
              <Routes>
                {valueLocalStorage?.data?.user?.role === 'admin' ?
                    <Route path="/" element={<Statistics />}/>
                    :
                    <Route path="/" element={<VendoresStatistics />}/>
              }
          
                <Route path="/OrdersManagement" element={<OrdersManagement />}/>
                <Route path="/Customers" element={<Customers />}/>
                <Route path="/DataCustomers" element={<DataCustomers />}/>
                <Route path="/Categoires" element={<Categoires />}/>
                <Route path="/AddCategoire" element={<AddCategoire />}/>
                <Route path="/CategoiresDetial" element={<CategoiresDetial />}/>
                <Route path="/Transactions" element={<Transactions />}/>
                <Route path="/Brand" element={<Brand />}/>
                <Route path="/AddCompany" element={<AddCompany />}/>
                <Route path="/Adveritsement" element={<Adveritsement />}/>
                <Route path="/AddAdveritsement" element={<AddAdveritsement />}/>
                <Route path="/ProductsList" element={<ProductsList />}/>
                <Route path="/AddProduct" element={<AddProduct />}/>
                <Route path="/Favorites" element={<Favorites />}/>
                <Route path="/Vendor" element={<Vendor />}/>
                <Route path="/DataVendor" element={<DataVendor />}/>
                <Route path="/AddCoupon" element={<AddCoupon />}/>
                <Route path="/Cart" element={<Cart />}/>
                <Route path="/Invoice" element={<Invoice />}/>
                <Route path="/Header" element={<Header />}/>
                <Route path="/CustomerReports" element={<CustomerReports />}/>
                <Route path="/InventoryReports" element={<InventoryReports />}/>
                <Route path="/AddAllCoupon" element={<AddAllCoupon />}/>
                <Route path="/CreateDiscount" element={<CreateDiscount />}/>
                <Route path="/AddDiscount" element={<AddDiscount />}/>

                
                <Route path="/VendorProduct" element={<VendorProduct />}/>
                <Route path="/ProductReports" element={<ProductReports />}/>
                <Route path="/CategorysVendors" element={<CategorysVendors />}/>
                <Route path="/Profile" element={<Profile />}/>
                <Route path="/VendorOrderManagement" element={<VendorOrderManagement />}/>
              </Routes>
           
          </div>
        </> 
      <Sidebar/>
      </BrowserRouter>

    </div>
  );
}

export default App;

