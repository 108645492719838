import React, { useState, useCallback } from 'react';
import './Profile.css';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudUpload } from '@fortawesome/free-solid-svg-icons';
import { useDropzone } from 'react-dropzone';
import Header from './Header/Header';
import { update_profile } from "./Redux/API"; // تأكد من تعديل المسار الصحيح لـ action

const Profile = () => {
  const dispatch = useDispatch();
  const products = useSelector(state => state?.Data?.product); // بيانات المنتجات من redux

  // جلب البيانات المخزنة من الـ localStorage
  const getLocalStorage = window.localStorage.getItem('UserInfo');
  const valueLocalStorage = getLocalStorage ? JSON.parse(getLocalStorage) : null;

  // التأكد من وجود البيانات في الـ localStorage
  const profile = valueLocalStorage?.data?.[0]?.cancelled_orders || [];
  console.log(profile);
  console.log(valueLocalStorage);

  // إنشاء state منفصل لكل حقل مع التحقق من وجود البيانات في localStorage
  const [name, setName] = useState(valueLocalStorage?.data?.[0]?.vendor_info?.created_at || '');
  const [phone, setPhone] = useState(valueLocalStorage?.data?.[0]?.phone_number || '');
  const [email, setEmail] = useState(valueLocalStorage?.data?.[0]?.email || '');
  const [companyName, setCompanyName] = useState(valueLocalStorage?.data?.[0]?.vendor_info?.company || '');
  const [activityType, setActivityType] = useState(valueLocalStorage?.data?.[0]?.vendor_info?.activity_type || '');
  const [address, setAddress] = useState(valueLocalStorage?.data?.[0]?.vendor_info?.address || '');
  const [numberOfProducts, setNumberOfProducts] = useState(valueLocalStorage?.data?.[0]?.vendor_info?.number_of_products || '');
  const [typeStore, setTypeStore] = useState(valueLocalStorage?.data?.[0]?.vendor_info?.category || '');
  
  // حفظ الصورة المحملة أو القادمة من الـ localStorage
  const [singleImage, setSingleImage] = useState(valueLocalStorage?.data?.[0]?.user_image || null);

  // تحميل الصورة باستخدام `react-dropzone`
  const onDropSingle = useCallback((acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      setSingleImage(acceptedFiles[0]); // حفظ الملف المحمل في state
    }
  }, []);

  const { getRootProps: getRootPropsSingle, getInputProps: getInputPropsSingle } = useDropzone({ 
    onDrop: onDropSingle, 
    multiple: false 
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    // إعداد البيانات للإرسال مع التحقق من الحقول المطلوبة
    const updatedData = {
      name,
      phone_number: phone, // حقل الهاتف
      email,
      company: companyName, // اسم الشركة
      activity_type: activityType, // نوع النشاط
      address,
      number_of_products: numberOfProducts, // عدد المنتجات
      category: typeStore, // الفئة
      image: singleImage, // الصورة (إذا كانت موجودة)
    };

    // إرسال البيانات إلى الباكند
    dispatch(update_profile(updatedData));
  };

  return (
    <div className="Profile">
      <Header />
      <div className='container-fluid mt3'>
        <form className="profile-card" onSubmit={handleSubmit}>
          <div className="col-4 mt-3">
            <div className="Add_img">
              <div className="add" {...getRootPropsSingle()}>
                <input {...getInputPropsSingle()} />
                {!singleImage && (
                  <div className="upload-placeholder">
                    <i><FontAwesomeIcon icon={faCloudUpload} /></i>
                    <p>Drag And Drop Single</p>
                  </div>
                )}
                {singleImage && (
                  // عرض الصورة بناءً على ما إذا كانت الصورة ملف محلي أو رابط
                  <img 
                    src={singleImage instanceof File ? URL.createObjectURL(singleImage) : singleImage} 
                    alt="Preview" 
                    className="img" 
                  />
                )}
              </div>
            </div>
          </div>
          <div className="profile-info">
            <input
              type="text"
              name="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="الاسم"
            />
            <input
              type="text"
              name="phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              placeholder="رقم الهاتف"
            />
            <input
              type="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="البريد الإلكتروني"
            />
            <input
              type="text"
              name="CompanyName"
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
              placeholder="اسم الشركة"
            />
            <input
              type="text"
              name="Activity_type"
              value={activityType}
              onChange={(e) => setActivityType(e.target.value)}
              placeholder="نوع النشاط"
            />
            <input
              type="text"
              name="address"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              placeholder="العنوان"
            />
            <input
              type="number"
              name="Number_of_products"
              value={numberOfProducts}
              onChange={(e) => setNumberOfProducts(e.target.value)}
              placeholder="عدد المنتجات"
            />
            <input
              type="text"
              name="category"
              value={typeStore}
              onChange={(e) => setTypeStore(e.target.value)}
              placeholder="الفئة"
            />

            <button type="submit" className="save-button">حفظ التعديلات</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Profile;