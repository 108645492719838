// Import the necessary modules from React, react-chartjs-2, and chart.js
import React, { useEffect, useState } from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels'; // استيراد الـ plugin الخاص بالـ datalabels
import { NavLink, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Header from './Header/Header';
import { useDispatch, useSelector } from 'react-redux';
import { low_stock, inventory_report , inventory_overview} from "./Redux/API";
import Loader from './Loader';

// Register the required elements with Chart.js
ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels); // تسجيل الـ plugin مع Chart.js

// Define the PieChart component
function PieChart() {

  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();


  const low_stockS = useSelector(state => state.Data.low_stock);
  const loadingLow_stockS = useSelector(state => state.Data.loadingLow_stockS);

  const inventory_reports = useSelector(state => state.Data.inventory_report);
  const loadingInventory_report = useSelector(state => state.Data.loadingLow_stockS);


  const inventory_overviews = useSelector(state => state.Data.inventory_overview);
  const loadingInventory_overview = useSelector(state => state.Data.loadingInventory_overview);



  console.log(inventory_overviews)
  console.log('uuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuu')

  useEffect(() => {
    dispatch(low_stock())
    dispatch(inventory_report())
    dispatch(inventory_overview())
  }, [])



  const [chartData, setChartData] = useState({
    labels: ['Sold', 'Remaining'], // التصنيفات الأساسية
    datasets: [
      {
        label: 'Products Distribution',
        data: [0, 0], // بيانات مبدئية (هتتحدث من الباك اند)
        backgroundColor: [
          'rgba(75, 192, 192, 0.6)', // لون المنتجات المباعة
          'rgba(255, 159, 64, 0.6)', // لون المنتجات المتبقية
        ],
        borderColor: [
          'rgba(75, 192, 192, 1)',
          'rgba(255, 159, 64, 1)',
        ],
        borderWidth: 1,
      },
    ],
  });

  // Simulate fetching data from the backend
  useEffect(() => {
    // هنا بنجيب البيانات من الباك اند - هتستبدل الـ API call دي بواحدة حقيقية
    const fetchDataFromBackend = async () => {
      // بيانات تجريبية للـ products من الباك اند
      const backendData = {
        totalProducts:inventory_overviews?.data?.data?.total_products || 0,
        soldProducts:inventory_overviews?.data?.data?.sold_products || 0,
        remainingProducts: inventory_overviews?.data?.data?.remaining_products || 0,
        
      };

      // حدث بيانات الـ chart بناءً على البيانات القادمة من الباك اند
      setChartData({
        labels: ['Sold', 'Remaining'],
        datasets: [
          {
            label: 'Products Distribution',
            data: [backendData.soldProducts, backendData.remainingProducts],
            backgroundColor: [
              '#FF4C4C', // لون المنتجات المباعة
              '#FFB22C', // لون المنتجات المتبقية
            ],
            borderColor: [
              'rgba(75, 192, 192, 1)',
              'rgba(255, 159, 64, 1)',
            ],
            borderWidth: 1,
          },
        ],
      });
    };

    // استدعاء الدالة لجلب البيانات
    fetchDataFromBackend();
  }, []);


  // Define the options for the chart including data labels
  const options = {
    plugins: {
      datalabels: {
        color: '#000', // لون النص
        font: {
          weight: 'bold',
          size: 16, // حجم النص
        },
        formatter: (value, context) => {
          // حساب النسبة المئوية
          const total = context.dataset.data.reduce((acc, val) => acc + val, 0);
          const percentage = ((value / total) * 100).toFixed(2); // حساب النسبة
          return `${percentage}%`; // إرجاع النسبة
        },
        anchor: 'center', // لضمان أن النص يكون في منتصف المسافة بين المركز والحافة
        align: 'center', // تحريك النص لليمين
        offset: 0, // تقليل المسافة وتحريك النص باتجاه الحافة اليمنى
      },
    },
  };
  

  return <Pie data={chartData} options={options} />;
}

export default PieChart;
