import './Statistics.css';
import './VendoresStatistics.css';
import SalesChart from './SalesChart';
import PieChart from './PieChart';
import RevenueComparisonChart from './RevenueComparisonChart'
import { faCartPlus, faChartPie } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useNavigate } from 'react-router-dom';
import Header from './Header/Header';
import { useDispatch, useSelector } from 'react-redux';
import { low_stock, inventory_report, user_distribution, shipped_orders_count, address_distribution } from "./Redux/API";
import { useEffect } from 'react';
import Loader from './Loader';

function VendoresStatistics() {
    const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();


  const low_stockS = useSelector(state => state.Data.low_stock);
  const loadingLow_stockS = useSelector(state => state.Data.loadingLow_stockS);

  const inventory_reports = useSelector(state => state.Data.inventory_report);
  const user_distributions = useSelector(state => state.Data.user_distribution);
  const shipped_orders_counts = useSelector(state => state.Data.shipped_orders_count);
  const address_distributions = useSelector(state => state.Data.address_distribution);
  const loadingInventory_report = useSelector(state => state.Data.loadingLow_stockS);
  console.log(address_distributions)
  console.log(user_distributions)
  console.log('lllllllllllgggggggggggggggggggggggg')

  useEffect(() => {
    dispatch(shipped_orders_count())
    dispatch(address_distribution())
    dispatch(user_distribution())
    dispatch(low_stock())
    dispatch(inventory_report())
  }, [])





    const [selectedDate, setSelectedDate] = useState('2023-01-01');
    const [comparisonDate, setComparisonDate] = useState('2022-01-01');
  
    const handleDateChange = (event) => setSelectedDate(event.target.value);
    const handleComparisonDateChange = (event) => setComparisonDate(event.target.value);
    const backendData = {
        totalProducts: (inventory_reports?.data?.data?.products_out_of_stock.length) + (inventory_reports?.data?.data?.products_with_stock.length),
        soldProducts: inventory_reports?.data?.data?.products_with_stock.length,
        remainingProducts: inventory_reports?.data?.data?.products_out_of_stock.length,
      };

    return (
      <div className="Statistics">
            < Header />
          <div className='container'>
              <div className='row align-items-center'>
                  <div className='name_header d-flex'>
                      <i className='font-size3'><FontAwesomeIcon icon={faChartPie} /></i>
                      <p>{t('statistics.title')}</p>
                  </div>
              </div>
              <div className='container'>
                  <div className='row justify-content-between align-items-center mt-5'>
                    <div className='col-6'>
                            <h4 className='mb-3'>{t('statistics.inventoryManagement')}</h4>
                            <div className='d d-flex justify-content-between '>
                                    <p>Total Products ({backendData.totalProducts})</p>
                                    <p>Sold Products ({backendData.soldProducts})</p>
                                    <p>Remaining Products ({backendData.remainingProducts})</p>
                                </div>
                            <div className='m-auto col-8' >
                                <PieChart />
                            </div>
                        </div>

                        <div className='col-6'>
                            <h4 className='mb-3'>الارباح</h4>
                          <RevenueComparisonChart />
                      </div>
                  </div>
              </div>

              
              <div className='container'>
                  <div className='row mt-4 mb-5'>

                          <div className='totals row justify-content-around align-items-center d-flex'>
                          <h4 className='mb-4'>{t('statistics.chooseDate')}</h4>
                              <div className='title col-5 mx-3 g-0 border-0 m-0 p-0 mb-3'>
                                  <input className='w-100'
                                  type="date"
                                  value={selectedDate}
                                  onChange={handleDateChange}
                                  />
                              </div>

                              <div className='title col-5 mx-3 g-0 border-0 m-0 p-0 mb-3'>
                                  <input className='w-100'
                                  type="date"
                                  value={comparisonDate}
                                  onChange={handleComparisonDateChange}
                                  />
                              </div>

                              <div className='col-2 '>
                                  <h5 className='text-center'>{t('statistics.netProfits')}</h5>
                                  <h5 className='mt-5 text-center'>12 </h5>
                              </div>
                              <div className='col-2'>
                                  <h5  className='text-center'>{t('statistics.averageOrderValue')}</h5>
                                  <h5 className='mt-5 text-center'>0 </h5>
                              </div>
                              <div className='col-2'>
                                  <h5  className='text-center'>{t('statistics.grossProfits')}</h5>
                                  <h5 className='mt-5 text-center'>0 </h5>
                              </div>
                              <div className='col-2'>
                                  <h5  className='text-center'>{t('statistics.profitMargins')}</h5>
                                  <h5 className='mt-5 text-center'>0 </h5>
                              </div>
                              <div className='col-2'>
                                  <h5  className='text-center'>عدد الطلبات المشحونة</h5>
                                  <h5 className='mt-5 text-center'>0 </h5>
                              </div>
                          </div>
                      
                  </div>
              </div>
          </div>
      </div>
    );
}

export default VendoresStatistics;