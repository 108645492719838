import './Statistics.css';
import SalesChart from './SalesChart';
import PieChart from './PieChart';
import RevenueComparisonChart from './RevenueComparisonChart'
import { faCartPlus, faChartPie } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useNavigate } from 'react-router-dom';
import Header from './Header/Header';
import { useDispatch, useSelector } from 'react-redux';
import { low_stock, inventory_report, user_distribution, shipped_orders_count, address_distribution, main_statistics } from "./Redux/API";
import { useEffect } from 'react';
import Loader from './Loader';

function Statistics() {
    const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();


  const low_stockS = useSelector(state => state.Data.low_stock);
  const loadingLow_stockS = useSelector(state => state.Data.loadingLow_stockS);

  const inventory_reports = useSelector(state => state.Data.inventory_report);
  const user_distributions = useSelector(state => state.Data.user_distribution);
  const shipped_orders_counts = useSelector(state => state.Data.shipped_orders_count);
  const address_distributions = useSelector(state => state.Data.address_distribution);
  const loadingInventory_report = useSelector(state => state.Data.loadingLow_stockS);



  console.log(low_stockS)
  console.log(inventory_reports)
  console.log(user_distributions)
  console.log(address_distributions)
  console.log(user_distributions)
//   console.log('lllllllllllgggggggggggggggggggggggg')

  useEffect(() => {
    dispatch(shipped_orders_count())
    dispatch(address_distribution())
    dispatch(user_distribution())
    dispatch(low_stock())
    dispatch(inventory_report())
  }, [])




  const main_statistic = useSelector(state => state.Data.main_statistics);
  const loadingMain_statistics = useSelector(state => state.Data.loadingMain_statistics);

  console.log(main_statistic)
  console.log('lllllllllllgggggggggggggggggggggggg')
    const [selectedDate, setSelectedDate] = useState('2023-01-01');
    const [comparisonDate, setComparisonDate] = useState('2022-01-01');
  
    const handleDateChange = (event) => setSelectedDate(event.target.value);
    const handleComparisonDateChange = (event) => setComparisonDate(event.target.value);
    const handleSubmit = () => {
        if (selectedDate && comparisonDate) {
          dispatch(main_statistics({ start_date: selectedDate, end_date: comparisonDate }));
        }
      };



      const inventory_overviews = useSelector(state => state.Data.inventory_overview);
      const backendData = {
        totalProducts:inventory_overviews?.data?.data?.total_products || 0,
        soldProducts:inventory_overviews?.data?.data?.sold_products || 0,
        remainingProducts: inventory_overviews?.data?.data?.remaining_products || 0,
    };

    return (
      <div className="Statistics">
            < Header />
          <div className='container'>
              <div className='row align-items-center'>
                  <div className='name_header d-flex'>
                      <i className='font-size3'><FontAwesomeIcon icon={faChartPie} /></i>
                      <p>{t('statistics.title')}</p>
                  </div>
              </div>
              <div className='container'>
                  <div className='row justify-content-between align-items-center mt-3'>
                      <div className='col-6'>
                          <SalesChart />
                      </div>

                      <div className='col-5'>
                          <div className='totals row justify-content-around align-items-center'>
                          <h4 className='mb-3'>{t('statistics.chooseDate')}</h4>
                              <div className='title col-5 g-0 border-0 m-0 p-0 mb-3'>
                                  <input className='w-100'
                                  type="date"
                                  value={selectedDate}
                                  onChange={handleDateChange}
                                  />
                              </div>

                              <div className='title col-5 g-0 border-0 m-0 p-0 mb-3'>
                                  <input className='w-100'
                                  type="date"
                                  value={comparisonDate}
                                  onChange={handleComparisonDateChange}
                                  />
                              </div>
                              <button onClick={handleSubmit} className='btn btn-primary col-11 mb-3'>
                                عرض البيانات
                                </button>
                          </div>
                      
                      </div>
                  </div>
              </div>
             <div className='container'>
                  <div className='row justify-content-between align-items-center mt-5'>
                      <div className='col-6'>
                          <RevenueComparisonChart />
                      </div>

                      <div className='col-5 '>
                          <div className='gross customer row justify-content-around'>
                               <div className='col-4 mb-3'>
                                  <div className='mb-3 px-2'>
                                      <h5>{t('statistics.grossProfits')}</h5>
                                      <span>{main_statistic?.data?.data?.grossProfit}</span>
                                  </div>
                              </div>
                              <div className='col-4'>
                                  <div className='px-2'>
                                      <h5>{t('statistics.profitMargins')}</h5>
                                      <span>{main_statistic?.data?.data?.profit_margin}</span>
                                  </div>
                              </div>
                              <div className='col-4'>
                                  <div className='px-2'>
                                      <h5>{t('statistics.durationOfStay')}</h5>
                                      <span>{main_statistic?.data?.data?.average_duration_percentage}</span>
                                  </div>
                              </div>
                              <div className='col-4'>
                                  <div className='px-2'>
                                      <h5>{t('statistics.bounceRate')}</h5>
                                      <span>{main_statistic?.data?.data?.bounce_rate}</span>
                                  </div>
                              </div>





                              <div className='col-4'>
                                  <div className='px-2'>
                                      <h5>{t('statistics.averageDeliveryTime')}</h5>
                                      <span>{main_statistic?.data?.data?.averageDeliveryTime} Hour</span>
                                  </div>
                              </div>
                              <div className='col-4'>
                                  <div className='px-2'>
                                      <h5>{t('statistics.averageOrderValue')}</h5>
                                      <span>{main_statistic?.data?.data?.average_order_value}</span>
                                  </div>
                              </div>
                           
                          </div>
                          <h4>{t('statistics.conversionRate')}</h4>

                          <div className='gross customer row justify-content-around'>
                              <div className='col-4'>
                                  <div className='px-2'>
                                      <h5>{t('statistics.visitorsBought')}</h5>
                                      <span>{main_statistic?.data?.data?.users_who_bought}</span>
                                  </div>
                              </div>
                              <div className='col-4'>
                                  <div className='px-2'>
                                      <h5>{t("statistics.visitorsDidntBuy")}</h5>
                                      <span>{main_statistic?.data?.data?.users_who_did_not_buy}</span>
                                  </div>
                              </div>
                              <div className='col-4'>
                                  <div className='px-2'>
                                      <h5>{t('statistics.visitorsToBuyersRate')}</h5>
                                      <span>{main_statistic?.data?.data?.conversion_rate}</span>
                                  </div>
                              </div>
                          </div>

                          <h4>{t('statistics.shippedOrdersCount')}</h4>
                            <div className='gross customer row justify-content-around'>
                                <div className='col-4'>
                                    <div className='px-2'>
                                        <h5>{t('statistics.shippedOrdersCount')}</h5>
                                        <span>{shipped_orders_counts?.data?.data?.shipped_orders}</span>
                                    </div>
                                </div>

                                <div className='col-4'>
                                    <div className='px-2'>
                                        <h5>{t('statistics.averageOrderValue')}</h5>
                                        <span>{main_statistic?.data?.data?.average_order_value}</span>
                                        </div>
                                </div>
                            </div>

        

                          <h4>{t('statistics.customerRatings')}</h4>

<div className='gross customer row justify-content-around'>
    <div className='col-4'>
        <div className='px-2'>
            <h5>{t('statistics.age')}</h5>
            <span>{user_distributions?.data?.data[0]?.age_group}</span>
        </div>
    </div>
    <div className='col-4'>
        <div className='px-2'>
            <h5>{t('statistics.gender')}</h5>
            <span>{user_distributions?.data?.data[0]?.gender}</span>
        </div>
    </div>
    <div className='col-4'>
        <div className='px-2'>
            <h5>{t('statistics.count')}</h5>
            <span>{user_distributions?.data?.data[0]?.count}</span>
        </div>
    </div>
</div>
                      </div>
                  </div>
              </div>
              <div className='container'>
                  <div className='row justify-content-between align-items-center mt-5'>
                      <div className='col-6'>
                          <h4>{t('statistics.inventoryManagement')}</h4>
                          <div className='d d-flex justify-content-between'>
                                <p>Total Products ({backendData.totalProducts})</p>
                                <p>Sold Products ({backendData.soldProducts})</p>
                                <p>Remaining Products ({backendData.remainingProducts})</p>
                            </div>
                          <div className='m-auto col-8' >
                              <PieChart />
                          </div>
                      </div>
                      {/* <div className="col-5 amla">
                            <h4 className="mb-3">العملاء الزائرين بدون تسجيل</h4>

                            <div className="d-flex justify-content-between totle">
                                <div className="text-center">
                                <p className=""><span>0</span></p>
                                <p>الزائرين بدون تسجيل</p>
                                </div>
                                <div className="text-center">
                                <p className=""><span>0</span></p>
                                <p>تصفحوا الموقع بدون شراء</p>
                                </div>
                            </div>

                            <div className="mt-4 adrs">
                                {Orders2.map((e, index) => (
                                <p key={index}>الفيوم</p> 
                                ))}
                            </div>
                        </div> */}
                  </div>
              </div>
              
              {/* <div className='container'>
                  <div className='row justify-content-between align-items-center mt-5'>
                  <h4>تنزيل</h4>
                      <div className='col-6 download '>
                        <button>تنزيل جميع الأرقام و الايميلات والمناطق الخاصة بالعملاء المسجلين (تنزيل)	</button>
                        <button>إمكانية تحويل أي ارقام حسابية الى ملف اكسل (تنزيل)	</button>
                        <button>تحليلات و تقارير	(تنزيل)				
                        </button>
                      </div>
                  </div>
              </div> */}
          </div>
      </div>
    );
}

export default Statistics;