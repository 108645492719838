import React, { useState, useCallback } from "react";
import './AddAdveritsement.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faCloudUpload } from "@fortawesome/free-solid-svg-icons";
import { useDropzone } from 'react-dropzone';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Header from './Header/Header';
import { useDispatch, useSelector } from 'react-redux';
import { post_ads, edit_ad } from "./Redux/API";
import Loader from './Loader';

function AddAdveritsement() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const ad = useSelector(state => state?.products?.ads || null);
    const loadingPost_ads = useSelector(state => state.Data.loadingPost_ads);

    // حالة اسم المهمة
    const [taskName, setTaskName] = useState(ad?.title || ""); 
    // حالة الصورة الواحدة
    const [singleImage, setSingleImage] = useState(ad?.image_url ? [{ preview: ad.image_url }] : []); 
    const [showPopup, setShowPopup] = useState(false); // حالة ظهور الـ popup

    console.log(singleImage, taskName);

    // وظيفة معالجة الصور عند سحبها
    const onDropSingle = useCallback((acceptedFiles) => {
        if (acceptedFiles.length > 0) {
            const file = acceptedFiles[0];
            setSingleImage([{ preview: URL.createObjectURL(file), file }]);
        }
    }, []);

    const { getRootProps: getRootPropsSingle, getInputProps: getInputPropsSingle } = useDropzone({ onDrop: onDropSingle, multiple: false });

    // إعادة تعيين المدخلات
    const resetInputs = () => {
        setTaskName("");
        setSingleImage([]);
    };

    // التحقق من المدخلات
    const validateInputs = () => {
        return taskName.trim() !== "" && singleImage.length > 0;
    };

    // رفع البيانات
    const uploadTask = (e) => {
        e.preventDefault();

        if (!validateInputs()) {
            setShowPopup(true);
            setTimeout(() => setShowPopup(false), 3000);
            return;
        }

        const adData = {
            name: taskName,
            description: 'وصف الإعلان', // يمكنك تعديله بناءً على الاحتياج
            category_id: '2', // قم بتحديثه بناءً على الفئة
            singleImage: singleImage[0].file, // استخدام أول صورة فقط
            link: undefined
        };

        // تحقق مما إذا كان هناك إعلان موجود لتحديثه أو إنشاء إعلان جديد
        if (ad) {
            dispatch(edit_ad({ ...adData, id: ad.id }));
        } else {
            dispatch(post_ads(adData));
        }

        resetInputs();
    };

    // العودة إلى الصفحة السابقة
    const goBack = () => {
        navigate(-1);
    };

    return (
        <div className="AddAdveritsement">
            <Header />
            <div className="container">
                {loadingPost_ads && <Loader />}
                <div className='row align-items-center'>
                    <div className="container icon">
                        <i className="i" onClick={goBack}><FontAwesomeIcon icon={faArrowLeft} /></i>
                    </div>
                </div>
                <form className="container row justify-content-around" onSubmit={uploadTask}>
                    <div className="row justify-content-between">
                        <div className="n col-5 mt-4">
                            <div className="divll col-12">
                                <label htmlFor='name'>{t('name')}</label>
                                <input
                                    type="text"
                                    id="Name"
                                    onChange={(e) => setTaskName(e.target.value)}
                                    value={taskName}
                                    required
                                />
                            </div>
                            <button className="button2 col-12 mt-5" type="submit">
                                {ad ? t('edit') : t('create')}
                            </button>
                        </div>

                        <div className="col-7">
                            <div className="Add_img">
                                <p>{t('addCategory.singlePhoto')}</p>
                                <div className="add" {...getRootPropsSingle()}>
                                    <input {...getInputPropsSingle()} />
                                    {singleImage.length === 0 ? (
                                        <div className="upload-placeholder">
                                            <i><FontAwesomeIcon icon={faCloudUpload} /></i>
                                            <p>{t('addCategory.dragAndDropSingle')}</p>
                                        </div>
                                    ) : (
                                        <img
                                            src={singleImage[0].preview}
                                            alt="Preview"
                                            className="img"
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div className={`popup ${showPopup ? 'show' : ''}`}>{t('addCategory.errorFields')}</div>
        </div>
    );
}

export default AddAdveritsement;