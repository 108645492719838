import './ProductsList.css';
// import './VendorProducts.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartPlus, faChevronRight, faEdit, faListAlt, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Header from './Header/Header';
import { NavLink, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { searsh_product, product, delete_product, vendor_products } from "./Redux/API";
import Loader from './Loader';
import { useEffect } from 'react';
import { EditProduct } from "./Redux/HomeSlice";


function VendorProduct() {
  
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const vendor_product = useSelector(state => state.Data.vendor_products);
  const loadingVendor_products = useSelector(state => state.Data.loadingVendor_products);
  const loadingDelete_product = useSelector(state => state.Data.loadingDelete_product);

console.log(vendor_product)

  
  // **حالة البحث**
  const [searshBroduct, setSearshBroduct] = useState('');
  const handleInputChangeReturns = (e) => {
    setSearshBroduct(e.target.value); 
  };

  useEffect(() => {
      dispatch(searsh_product(searshBroduct)); // استدعاء البحث
  }, [searshBroduct, dispatch]);

  useEffect(() => {
      dispatch(vendor_products()); 
  }, [dispatch, loadingDelete_product]);

  const handleDeleteProduct = (id) => {
    dispatch(delete_product(id));
  };

  const listOrders = [t('products_list.product_name'), t('products_list.category'), 'السعر', "العلامة التجارية", t('products_list.status'), t('products_list.action')];
  const listHidear = [t('products_list.active'), t('products_list.draft'), t('products_list.archive')];

  const [categories, setCategories] = useState([t('products_list.draft'), t('products_list.active'), t('products_list.archive')]);
  const [openCategory, setOpenCategory] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState({});

  const handleCategoryClick = (id) => {
    setOpenCategory(openCategory === id ? null : id);
  };

  const handleSelectCategory = (id, category) => {
    setSelectedCategory({ ...selectedCategory, [id]: category });
    setOpenCategory(null);
  };

  const gotoedit_Product = (item) => {
    dispatch(EditProduct(item));
    navigate('/AddProduct');
  };

  return (
    <div className="ProductsList">
      <Header />
      <div className='container-fluid'>
        <div className='row align-items-center'>
          <div className='name_header d-flex'>
            <i className='font-size3'><FontAwesomeIcon icon={faListAlt} /></i>
            <p>{t('products_list.products_list')}</p>
          </div>
        </div>


        <div className='container mt-4'>
          <div className='row'>
            <div className='sarsh col-5'>
            <input type="text" value={searshBroduct} onChange={handleInputChangeReturns} placeholder={t('products_list.search')} />

            </div>
          </div>
        </div>

        <div className='container'>
          {loadingVendor_products && <Loader />}
          {loadingDelete_product && <Loader />}
          <div className='Orders'>
            <div className='Orders_border row'>
              {listOrders.map((e, index) => (
                <p className='col-2 text-center mb-3' key={index}>{e}</p>
              ))}
            </div>
            {vendor_product?.data?.data.map((item) => (
              <div key={item.id}>
                <div className='Orders_border row align-items-center'>
                  <p className='col-2  d-flex text-start'>
                    <img src={item.main_image} alt={item.id}/>
                    <span>{item.name}</span>
                  </p>
                  <p className='col-2 text-center'>{item.category}</p>
                  <p className='col-2 text-center'>{item.new_price}</p>
                  <p className='col-2 text-center'>{item.brand}</p>
                  <p className='col-2 text-center'>Active</p>
                  <p className='col-2 text-center'>
                  <span className='span_icon' onClick={() => gotoedit_Product(item)}><FontAwesomeIcon icon={faEdit} /></span>
                  <span 
                      className='span_icon2' 
                      onClick={() => handleDeleteProduct(item.id)}  // تمرير الـ id للباك إند
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </span>
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default VendorProduct