import './ProductsList.css';
import './AddCoupon.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { product, add_coupon, all_coupon } from "./Redux/API";
import Loader from './Loader';
import Header from './Header/Header';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

function AddCoupon() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const products = useSelector(state => state.Data.product);
  const loadingProduct = useSelector(state => state.Data.loadingProduct);
  
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedCouponId, setSelectedCouponId] = useState(""); // Store coupon ID
  const [errorMessage, setErrorMessage] = useState(""); // Error message state

  // Load products when the page is opened
  useEffect(() => {
    dispatch(product());
  }, [dispatch]);

  const loadingAll_coupon = useSelector(state => state.Data.loadingAll_coupon);
  const loadingAdd_coupon = useSelector(state => state.Data.loadingAdd_coupon);
  const all_coupons = useSelector(state => state.Data.all_coupon);
  console.log(all_coupons);

  useEffect(() => {
    dispatch(all_coupon());
  }, [dispatch]);

  // Select or deselect a product when clicking the checkbox
  const handleSelectProduct = (e, productId) => {
    if (e.target.checked) {
      setSelectedProducts([...selectedProducts, productId]);
    } else {
      setSelectedProducts(selectedProducts.filter(id => id !== productId));
    }
  };

  // Update the coupon value when selecting an item from the dropdown
  const handleCouponChange = (e) => {
    setSelectedCouponId(e.target.value);
  };

  const handleSubmit = () => {
    if (!selectedCouponId && selectedProducts.length === 0) {
      setErrorMessage(t('errors.select_coupon_and_products')); 
    } else if (!selectedCouponId) {
      setErrorMessage(t('errors.select_coupon')); 
    } else if (selectedProducts.length === 0) {
      setErrorMessage(t('errors.select_products'));
    } else {
      setErrorMessage(""); 
      dispatch(add_coupon({
        product_ids: selectedProducts,
        coupon_id: selectedCouponId // Send the coupon ID
      }));
    }
  };

  const listOrders = [t('products_list.product_name'), t('products_list.category'), t('products_list.price'), t('products_list.brand'), 'coupon'];
console.log(products?.data?.data)
  return (
    <div className="ProductsList">
      <Header />
      <div className='container-fluid'>
        <div className='row align-items-center'>
          <div className='name_header d-flex'>
            <i className='font-size3'><FontAwesomeIcon icon={faHeart} /></i>
            <p>{t('favorites')}</p>
          </div>
        </div>

        <div className='container mt-3'>
          <div className='row'>
            {errorMessage && (
              <p className="error-message text-center" style={{ color: 'red' }}>{errorMessage}</p> 
            )}
            <div className='sarsh justify-content-between d-flex mt-3 mb-3'>
              <div>
                <input 
                  placeholder={t('products_list.search')} 
                />
              </div>
              <div>
                <span>{t('actions.add_coupon')}</span>
                {/* Coupon dropdown */}
                <div className="form-group w-100">
                  <select 
                    id="couponSelect"
                    className="form-select select-coupons w-100"
                    value={selectedCouponId} 
                    onChange={handleCouponChange}
                  >
                    <option value="">{t('select_coupon')}</option>
                    {all_coupons?.data?.data?.coupons.map(coupon => (
                      <option key={coupon.id} value={coupon.id}>
                        {`Code ${coupon.code}`} &nbsp;&nbsp;&nbsp;&nbsp; {`Discount ${coupon.discount_value}`}
                      </option>
                    ))}
                  </select>
                </div>
                <button onClick={handleSubmit}>{t('actions.apply_coupon')}</button>
              </div>
            </div>
          </div>
        </div>

        <div className='container'>
          {loadingProduct && <Loader />}
          {loadingAdd_coupon && <Loader />}
          <div className='Orders'>
            <div className='Orders_border row justify-content-between'>
              {listOrders.map((e, index) => (
                <p className='col-2 text-center mb-3' key={index}>{e}</p>
              ))}
            </div>

            {products?.data?.data.map((item) => (
              <div key={item.id}>
                <div className='Orders_border row align-items-center justify-content-between'>
                  <input 
                    type="checkbox" 
                    onChange={(e) => handleSelectProduct(e, item.id)} 
                  />
                  <p className='col-2 text-center'><span>{item.name}</span></p>
                  <p className='col-2 text-center'>{item?.category}</p>
                  <p className='col-2 text-center'>{item?.new_price}</p>
                  <p className='col-2 text-center'>{item?.brand}</p>
                  <p className='col-2 text-center'>{item?.colors[0]?.name}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddCoupon;