import React, { useState, useEffect } from "react";
import './AddAllCoupon.css';
import './CreateDiscount.css';
import Header from './Header/Header';
import { useDispatch, useSelector } from 'react-redux';
import { create_discount, all_discount, delete_discount } from "./Redux/API";
import Loader from './Loader';
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CreateDiscount = () => {
  const { t } = useTranslation(); // لاستخدام الترجمة
  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  const loadingCreate_discount = useSelector(state => state.Data.loadingCreate_discount);
  const loadingAll_discount = useSelector(state => state.Data.loadingAll_discount);
  const loadingDelete_discount = useSelector(state => state.Data.loadingDelete_discount);
  const all_discounts = useSelector(state => state.Data.all_discount);
  const errorCreate_discount = useSelector(state => state.Data.errorCreate_discount);

  useEffect(() => {
    dispatch(all_discount());
  }, [dispatch, loadingCreate_discount, loadingDelete_discount]);

  // تعريف حالات منفصلة لكل حقل
  const [discountValue, setDiscountValue] = useState(null);
  const [code, setCode] = useState('');
  const [usageLimit, setUsageLimit] = useState("0");
  const [expiryDate, setExpiryDate] = useState("percentage");
  
  const [errors, setErrors] = useState(""); 

  const handleChangeDiscountValue = (e) => {
    setDiscountValue(parseFloat(e.target.value)); // تحويل القيمة إلى number
};


  const handleChangeCode = (e) => {
    setCode(e.target.value);
  };

  const handleChangeUsageLimit = () => {
    setUsageLimit(prevLimit => (prevLimit === "1" ? "0" : "1"));
  };

  const handleChangeExpiryDate = (e) => {
    setExpiryDate(e.target.value);
  };

  const validateForm = () => {
    if (!discountValue || !code || !usageLimit || !expiryDate) {
      return false; 
    }
    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  
    if (validateForm()) {
      const formData = {
        discount_value: discountValue,
        code: code,
        usage_limit: usageLimit,
        expiry_date: expiryDate,
      };

      // إرسال البيانات المعدلة إلى الباك اند
      dispatch(create_discount(formData));

      // إعادة تعيين الحقول بعد الإرسال
      setDiscountValue(null);
      setCode('');
      setUsageLimit(""); // إعادة تعيين القيمة الافتراضية
      setExpiryDate("");

      setErrors("");
    } else {
      setErrors(t('errors.fill_all_fields')); // استخدم الترجمة للخطأ
    }
  };

  const allCoupon = [t('coupon.name'), t('coupon.discount_value'), t('coupon.usage_limit'), t('coupon.Creation_date'), t('coupon.action')];

  const handleDeleteCoupon = (id) => {
    dispatch(delete_discount(id));
  };
console.log(errorCreate_discount?.data)
  return (
    <div className="AddAllCoupon">
      <Header />
      <div className='row align-items-center'>
        <div className='name_header d-flex'></div>
        <div className="container icon"></div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="container">
          {loadingDelete_discount && <Loader />}
          {loadingAll_discount && <Loader />}
          {loadingCreate_discount && <Loader />}
          <div className="row"> 
            <div className="Coupon">
              <label>{t('coupon.discount_value')}</label>
              <input
                type="number"
                value={discountValue}
                onChange={handleChangeDiscountValue}
              />
            </div>
            <div className="Coupon">
              <label>Discount Name</label>
              <input
                type="text"
                value={code}
                onChange={handleChangeCode}
              />
            </div>
            <div className="Coupon">
              <label>{t('coupon.usage_limit')}</label>
              <button className="w-100"
                type="button"
                onClick={handleChangeUsageLimit}
                style={{
                  backgroundColor: usageLimit === "1" ? "green" : "red",
                  color: "white",
                  padding: "10px 20px",
                  border: "none",
                  cursor: "pointer",
                }}
              >
                {usageLimit === "1" ? "Active (1)" : "Inactive (0)"}
              </button>
            </div>
            <div className="Coupon">
              <label>Discount Type</label>
              <input
                type="text"
                value={expiryDate}
                onChange={handleChangeExpiryDate}
                placeholder="percentage"
                required
                readOnly  // الحقل غير قابل للتعديل
              />
            </div>
            <button className="button" type="submit">{t('actions.submit')}</button>
          </div>
        </div>
      </form>

      {errors && <p style={{ color: "red" }}>{errors}</p>}
      {errorCreate_discount && <p style={{ color: "red" }}>{errorCreate_discount?.data?.data?.message}</p>}

      <div className='container'>
        <div className='Orders px-3 mt-5'>
          <div className='Orders_border row justify-content-around'>
            {allCoupon.map((e, index) => (
              <p className='col-2 text-center border-0' key={index}>{e}</p>
            ))}
          </div>

          {all_discounts?.data?.data?.discounts.map((order) => (
            <div key={order.id}>
              <div className='div_Transactions d-flex justify-content-around'>
                <p className='col-2 text-center'>{order.name}</p>
                <p className='col-2 text-center'>{order.discount_value}</p>
                <p className='col-2 text-center'>{order.is_active}</p>
                <p className='col-3 text-center'>{order.created_at}</p>
                <p className='col-2 text-center'>
                  <span 
                    className='span_icon2' 
                    onClick={() => handleDeleteCoupon(order.id)}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </span>
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CreateDiscount;